import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
    } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';

import { AuthService } from '../DAL/auth.service';

@Injectable()
export class JudgeAuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router,
        private storage: LocalStorageService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        let isAuth;
        if (this.storage.retrieve('isAuthenticated') == 'true') {
          if (this.storage.retrieve('user') && (this.storage.retrieve('user')['usergroup'] == 2 || this.storage.retrieve('user')['usergroup'] == 3)) {
            isAuth = true;
          } else {
            isAuth = false;
          }

        } else {
          isAuth = false;
        }
        if (!isAuth) {
          this.authService.revokeToken();
          // this.router.navigate(['/login']);
        }
        return isAuth;
      }

}
