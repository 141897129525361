import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend,  } from '@angular/common/http';
import { Router } from '@angular/router';

import { Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import * as sha512 from 'js-sha512';
import { LocalStorageService } from 'ngx-webstorage';

import { environment } from '../../../environments/environment';

const URL = `${environment.apiUrl}`;

@Injectable()

export class AuthService {

  private isAuthenticated = false;
  private token: string;
  private tokenTimer: any;
  private refreshToken: string;

  currentLang: any;

  private loginStatusSource = new BehaviorSubject('empty');
  loginStatus = this.loginStatusSource.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private handler: HttpBackend,
    private storage: LocalStorageService
  ) {
      this.http = new HttpClient(handler);
      if (this.storage.retrieve('currentlang')) {
        this.currentLang = this.storage.retrieve('currentlang')['key'];
      }
      this.storage.observe('currentlang')
        .subscribe((value) => {
          this.currentLang = value['key'];
        });
  }

  // createUser(email: string, password: string) {
  //   const authData = { email, password };
  //   this.http.post(`${URL}/signup`, authData).subscribe(
  //     () => {
  //       this.router.navigate(['/']);
  //     },
  //     error => {
  //       this.authStatusListener.next(false);
  //     }
  //   );
  // }

  login(value) {
    const data = new URLSearchParams();
    data.set('username', value.username);
    data.set('password', sha512.sha512(value.password));
    data.set('grant_type', value.grant_type);

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    this.http.post(`${URL}/token`, data.toString(), options)
      .subscribe(
        response => {
          const token = response['access_token'];
          this.token = token;
          this.storage.store('user', response);
          this.loginStatusSource.next('success');
          if (token) {
            this.setAuthenticationData(token, response);
            this.isAuthenticated = true;
            if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 1) {
              this.setActiveMenus();
              this.setCurrentAcademicYear();
              this.router.navigate(['admin']);
            } else if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 2) {
              this.setActiveMenus();
              this.setCurrentAcademicYear();
              this.router.navigate(['judge']);
            } else if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 3) {
              this.setActiveMenus();
              this.setCurrentAcademicYear();
              this.router.navigate(['judge']);
            } else if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 4) {
              this.setActiveMenus();
              this.setCurrentAcademicYear();
              this.router.navigate(['admin']);
            } else {
              this.logout();
            }

            // this.router.navigate(['judge']);
          }
        },
        error => {
          this.loginStatusSource.next('failed');
        }
      );
  }

  forgotStaffPassword(email) {

    let lang = '';
    if (this.currentLang == 'ar') {
      lang = 'Ar';
    } else {
      lang = 'En';
    }
    return this.http.post(`${URL}/ForgotStaffPassword?Email=${email}&lang=${lang}`, {})
      .pipe(
        catchError(this.handleError)
      );
  }

  updatePassword(staffID, password) {
    return this.http.post(`${URL}/UpdatePassword?Password=${password}&StaffID=${staffID}&User=0`, { })
      .pipe(
        catchError(this.handleError)
      );
  }

  verifyLink(token) {
    return this.http.post(`${URL}/VerifyLink?un=${token}`, { })
      .pipe(
        catchError(this.handleError)
      );
  }

  geRrefreshToken() {
    const data = new URLSearchParams();
    data.set('refresh_token', this.storage.retrieve('refreshtoken'));
    data.set('grant_type', 'refresh_token');

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post<any>(`${URL}/token`, data.toString(), options)
      .pipe(tap((response: any) => {
          // this.storage.store('refreshtoken', res.refresh_token);
          // this.storage.store('token', res.access_token);
          const token = response['access_token'];
          this.token = token;
          this.storage.store('user', response);
          if (token) {
            this.setAuthenticationData(token, response);
            this.isAuthenticated = true;
          }

        },
        (err) => {
          this.clearAuthData();
          // this.storage.clear();
          this.loginStatusSource.next('');
          this.router.navigate(['/auth/login']);
        }
      ));
  }

  revokeToken() {
    const data = new URLSearchParams();
    data.set('refresh_token', this.storage.retrieve('refreshtoken'));
    data.set('grant_type', 'refresh_token');

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    this.http.post<any>(`${URL}/token`, data.toString(), options)
      .subscribe(
        response => {
          const token = response['access_token'];
          this.storage.store('user', response);
          this.token = token;
          if (token) {
            this.setAuthenticationData(token, response);
            this.isAuthenticated = true;
            if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 1) {
              this.setActiveMenus();
              this.setCurrentAcademicYear();
              this.router.navigate(['admin']);
            } else if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 2) {
              this.setActiveMenus();
              this.setCurrentAcademicYear();
              this.router.navigate(['judge']);
            } else {
              this.logout();
            }
            // this.router.navigate(['admin']);
          }
        },
        error => {
          this.loginStatusSource.next('');
          // this.storage.clear();
          this.clearAuthData();
          this.router.navigate(['/auth/login']);
        }
      );
  }

  setAuthenticationData(token, response) {
    const expiresInDuration = response['expires_in'];
    clearTimeout(this.tokenTimer);
    this.setAuthTimer(expiresInDuration);
    this.isAuthenticated = true;
    this.storage.store('isAuthenticated', 'true');
    this.refreshToken = response['refresh_token'];
    const now = new Date();
    const expirationDate = new Date(
      now.getTime() + expiresInDuration * 1000
    );
    this.saveAuthData(token, expirationDate, this.refreshToken, response['roles']);
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.storage.store('isAuthenticated', 'true');
      clearTimeout(this.tokenTimer);
      this.setAuthTimer(expiresIn / 1000);
    } else {
      this.logout();
    }
  }

  setActiveMenus() {
    this.FetchAllMenuBindings(this.storage.retrieve('user')['usergroup'])
      .subscribe((res: any) => {
        this.storage.store('activeMenus', JSON.parse(res));
      });
  }

  setCurrentAcademicYear() {
    this.FetchAllAcademicYear()
      .subscribe((res: any) => {
        const index = res.findIndex(ele => ele.CurrentAcademicYear == 2);
        if (index > -1) {
          this.storage.store('currentActiveAcademicYear', res[index]);
        }
      });
  }

  FetchAllAcademicYear(status = 2) {
    return this.http.get(`${URL}/FetchAllAcademicYear?Status=${status}`, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.retrieve('token'),
          Accept: '*/*',
        })
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  FetchAllMenuBindings(userGroupId) {
    return this.http.get(`${URL}/FetchAllMenuBindings?UserGroup=${userGroupId}`, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.retrieve('token'),
          Accept: '*/*',
        })
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  //#endregion
  FetchJudgeDetailsByID(JudgeID) {
    return this.http.get(`${URL}/FetchJudgeDetailsByID?JudgeID=${JudgeID}`, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.storage.retrieve('token'),
          Accept: '*/*',
        })
      })
      .pipe(
        catchError(this.handleError)
      );
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.storage.store('isAuthenticated', 'false');
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/']);
  }

  private setAuthTimer(duration: number) {
    duration = Math.abs(duration - 5);
    this.tokenTimer = setTimeout(() => {
      // this.logout();
      this.geRrefreshToken();
    }, duration * 1000);
  }

  private saveAuthData(token, expirationDate, refreshToken, roles) {
    this.storage.store('token', token || '');
    this.storage.store('expiration', expirationDate.toString() || '');
    this.storage.store('refreshToken', refreshToken || '');
    this.storage.store('roles', roles || '');
  }

  private clearAuthData() {
    this.storage.clear('token');
    this.storage.clear('expiration');
    this.storage.clear('refreshToken');
    this.storage.clear('roles');
    this.storage.clear('isAuthenticated');
    this.storage.clear('activemenus');
    this.storage.clear('user');
    this.storage.clear('currentacademicyear');
    // this.storage.clear();
  }

  private getAuthData() {
    const token = this.storage.retrieve('token');
    const expirationDate = this.storage.retrieve('expiration');
    const refreshToken = this.storage.retrieve('refreshToken');
    const roles = this.storage.retrieve('roles');
    if (!token || !expirationDate) {
      return;
    }
    return {
      token,
      expirationDate: new Date(expirationDate),
      refreshToken,
      roles
    };
  }

  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = error.error;
     }
     return throwError(errorMessage);
  }

}
