import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

import { AuthService } from './common/DAL/auth.service';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    AuthService
  ]
})
export class AppComponent implements OnInit {

  defaultLang = environment.defaultLang || '';
  currentLang: string;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private storage: LocalStorageService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    if (this.storage.retrieve('currentLang')) {
      this.onChangeLang(this.storage.retrieve('currentLang'));
    } else {
      this.translate.setDefaultLang(this.defaultLang);
      this.translate.use(this.defaultLang);
      this.currentLang = this.defaultLang;
    }
  }

  ngOnInit() {
    // document.querySelector('body').classList.add('height-100');
    this.authService.autoAuthUser();
  }

  onChangeLang(lang) {
    if (lang) {
      this.translate.use(lang.key);
      this.currentLang = lang.key;
      this.storage.store('currentLang', lang);
      this.currentLang = lang.key;
    }
  }

}
