import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';

import { AuthService } from '../DAL/auth.service';

@Injectable()
export class MenuResolver implements Resolve<any> {

  constructor(
    private authService: AuthService,
    private storage: LocalStorageService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.authService.FetchAllMenuBindings(this.storage.retrieve('user')['usergroup'])
      .subscribe((res: any) => {
        this.storage.store('activeMenus', JSON.parse(res));
        return true;
      });
  }

}
