import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { routes } from './app.routing';

// third party packages
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgImageSliderModule } from 'ng-image-slider';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxTinymceModule } from 'ngx-tinymce';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// interceptors
import { AuthInterceptor } from './common/interceptors/auth-interceptor';
import { ErrorInterceptor } from './common/interceptors/error-interceptor';

// guards
import { AdminAuthGuard } from './common/guards/admin-auth.guard';
import { JudgeAuthGuard } from './common/guards/judge-auth.guard';
import { NonAuthGuard } from './common/guards/non-auth.guard';

// Resolver
import { MenuResolver } from './common/interceptors/menu-resolver';

import { AuthService } from './common/DAL/auth.service';

// import component
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { JudgeComponent } from './judge/judge.component';
import { CommonComponent } from './common/common.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    JudgeComponent,
    CommonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory, // local
        useFactory: (createTranslateLoader), // aot
        deps: [HttpClient]
      }
    }),
    NgImageSliderModule,
    OwlModule,
    NgxTinymceModule.forRoot({
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/',
    })
  ],
  providers: [
    AuthService,
    AdminAuthGuard,
    JudgeAuthGuard,
    NonAuthGuard,
    MenuResolver,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
