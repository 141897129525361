import { Component, OnInit } from '@angular/core';

import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {

  constructor(
    private storageService: LocalStorageService
  ) { }

  ngOnInit() {
  }

}
