import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';

import { AuthService } from '../DAL/auth.service';

@Injectable()
export class NonAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private storage: LocalStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.storage.retrieve('isAuthenticated') == 'true') {
      if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 1) {
        this.router.navigate(['/admin']);
      } else if (this.storage.retrieve('user') && this.storage.retrieve('user')['usergroup'] == 2) {
        this.router.navigate(['/judge']);
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
